
.mobile-navigation {
    display: none;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {

    .mobile-navigation {
        display: block;
    }
    .desk-menu {
        display: none;
    }
    nav.mobile-nav {
        display: block;
        .navbar-nav{
            display: block;
        }
        ul {
            li {
                position: relative;
                border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                .sub-menu {
                    padding: 0 0 0 40px;
                    background: #f2eaea;
                }
                a {
                    display: block;
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -o-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                    color: $font-color!important;
                }
            }
            padding: 30px 0 0 0;
        }
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        height: 0;
        width: 100%;
        padding: 2em;
        -webkit-opacity: 0;
        -moz-opacity: 0;
        opacity: 0;
        -webkit-transform: scale(0.85);
        -ms-transform: scale(0.85);
        transform: scale(0.85);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        position: fixed;
        overflow-x: scroll;
        background: rgba(255, 255, 255, 1);
    }
     
    nav.mobile-nav.toggled {
        display: block;
        z-index: 10;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        -webkit-opacity: 1;
        -moz-opacity: 1;
        opacity: 1;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        height: 100vh;
        align-items: flex-start;
    }

    a.target-burger.toggled {
        //position: fixed;
        //right: 15px;
        ul.buns {
            li.bun {
                -webkit-transform: rotate(45deg) translateZ(0);
                transform: rotate(45deg) translateZ(0);
                &:last-child {
                    -webkit-transform: rotate(-45deg) translateZ(0);
                    transform: rotate(-45deg) translateZ(0);
                    position: relative;
                    top: -1px;
                }
            }
        }
    }
    a.target-burger {
        width: 3.25em;
        height: auto;
        position: relative;             
        display: block;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        z-index: 99;
        cursor: pointer;
        &:hover {
            cursor: pointer;
            -webkit-opacity: 1;
            -moz-opacity: 1;
            opacity: 1;
        }
        ul.buns {
            width: 1.625em;
            height: 1.625em;
            list-style: none;
            padding: 0;
            position: absolute;
            top:-4px;
            right: 0;
            margin: auto;
            -webkit-transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1), color 1s cubic-bezier(0.23, 1, 0.32, 1);
            transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), color 1s cubic-bezier(0.23, 1, 0.32, 1);
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            color: #fff;
            li.bun {
                width: 100%;
                height: 2px;
                background-color: $secondary-color;
                top: 50%;
                margin-top: -0.75px;
                -webkit-transform: translateY(-3.75px) translateZ(0);
                transform: translateY(-3.75px) translateZ(0);
                -webkit-transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1), background-color 1s cubic-bezier(0.23, 1, 0.32, 1);
                transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), background-color 1s cubic-bezier(0.23, 1, 0.32, 1);
                &:last-child {
                    -webkit-transform: translateY(3.75px) translateZ(0);
                    transform: translateY(3.75px) translateZ(0);
                }
            }
        }
    }

     
}