/* --- Header CSS Start --- */
.master-header {
    position: relative;
    padding: 0;
    background: transparent linear-gradient(180deg, #fafafb 0%, #ffffff 100%) 0% 0% no-repeat padding-box;

    .checkbox-toggle {
        display: none;
    }

    .top-sec {
        padding: 15px 0;
        .nav-brand
        {
            display: block;
        }

        .user-operations {
            .user-name {
                font-size: 16px;
                line-height: normal;
                color: $font-color;
            }

            .user-img { 
                .MuiAvatar-root {
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            .logout-btn {
                font-size: 0;

                img {
                    max-height: 18px;
                    height: 100%;
                    width: auto;
                    position: relative;
                    top: -4px;
                }
            }
        }

        .hd_loot {
            margin: 0 30px 0 0;
            border-right: 1px solid rgba(74, 73, 74, 0.1);
            padding: 0 30px 0 0;

            .lbimg {
                background: #fff;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
                height: 40px;
                width: 40px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                padding: 5px;
                margin: 0 15px 0 0;

                img {
                    animation: spinHorizontal 2s infinite linear;
                    -webkit-animation: spinHorizontal 2s infinite linear;
                }
            }

            .lb_vnt {
                font-size: 14px;
                color: rgba(74, 73, 74, 0.5);
                line-height: 18px;

                span {
                    display: block;
                    color: #16cf50;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }
    }

    .bottom-sec {
        border-bottom: 1px solid #e9e9e9;

        .nav-item {
            margin: 0 40px;
            position: relative;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            .nav-link {
                .nav_ic {
                    min-height: 32px;

                    img {
                        height: 100%;
                    }
                }

                .menu-icons {
                    opacity: 0.5;
                }

                color: rgba(74, 73, 74, 0.5);
                padding: 30px 0px 5px 0px;

                .tooltip {
                    position: relative;
                    opacity: 1;
                    display: inline-block;
                    z-index: 0;
                    margin: 0 15px 0 0;
                    animation: zoomTip 0.5s infinite linear;
                    -webkit-animation: zoomTip 0.8s infinite linear;

                    .tooltip-arrow {
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-color: transparent;
                        border-style: solid;
                        border-width: 5px 0px 5px 5px;
                        border-left-color: $secondary-color;
                        margin: auto 0 auto 0;
                        top: 0;
                        bottom: 0;
                        right: -5px;
                    }

                    .tooltip-inner {
                        background-color: $secondary-color;
                        font-size: 14px;
                        font-weight: 700;
                        color: #fff;
                        line-height: 18px;
                    }
                }
            }
        }

        .nav-item.active {
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 2px;
                background-color: $font-color;
                margin: 0 auto;
            }

            .nav-link {
                color: rgba(74, 73, 74, 1);

                .menu-icons {
                    opacity: 1;
                }
            }
        }
 

        .cmgson{
            display: block;
            text-align: center;
            font-size: 12px;
            color: #16cf50; 
        }
    }
}

/* --- Header CSS End --- */
@media only screen and (min-width: 768px) and (max-width: 991px) {

    .master-header .bottom-sec .nav-item
    {
        margin: 0 16px;
    }

}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .master-header {
        .top-sec 
        {
            .logo-box
            {
                width: 100%;
            }
            .nav-brand
            {
                margin: 0 0 15px 0;
            }
        }
        .bottom-sec {
            .nav-item {
                text-align: center;
                margin: 0;
            }
            .nav-item.active {
                &::after {
                    right: 0;
                    left: 0;
                }
            }
        }
        .user-name, .user-img  {
            display: none !important;
        }
        .user-cred-sec
        {
            width: 100%;
        }
        .hd_loot
        {
            display:block!important;
            width: 40%;
            margin: 0!important;
            padding: 0!important;
            text-align: center;
            .lbimg
            {
                margin: 0 auto 5px auto!important;
            }
        }
        .user-operations
        {
            display: block!important;
            width: 60%;
            padding: 0 0 0 15px;
            .user-name
            {
                width: 100%;
                max-width: calc(100% - 90px);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-align: right;
            }
        }
    }
}