.switch
{
    input[type=checkbox]{
        height: 0;
        width: 0;
        visibility: hidden;
        display: none;
    }

    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 60px;
        height: 30px;
        background: #fff;
        display: block;
        border-radius: 30px;
        border: 1px solid #E9E9E9;
        position: relative;
    }

    label:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 22px;
        height: 22px;
        background:lightgray;
        border-radius: 90px;
        transition: 0.3s;
    }

    input:checked + label:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
        background: #16CF50;
    }

    label:active:after {
        width: 30px;
    }
}