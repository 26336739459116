 .phoneverify {
    .modal-dialog {
        max-width: 360px;
    } 
 }
 .popup-sec {
    width: 100%;  
    .popup-title {
      background-color: #FAFAFB;
      padding: 30px 30px 45px 30px;
      font-size: 22px;
      font-weight: 300;
      color: #fff;
      background-color: #e62b4a;
      position: relative;
      text-transform: capitalize;
      border-radius: 0.3rem 0.3rem 0 0;
      -webkit-border-radius: 0.3rem 0.3rem 0 0;
      -moz-border-radius: 0.3rem 0.3rem 0 0;
      -ms-border-radius: 0.3rem 0.3rem 0 0;
      -o-border-radius: 0.3rem 0.3rem 0 0;
      
      &::after{
        content: '';
        height: 70px;
        width: 70px;
        border: 1px solid #E9E9E9;
        position: absolute;
        bottom: -35px;
        left: 0;
        right: 0;
        margin: 0 auto;
        border-radius: 50%;
        background-color: #fff;
        background-image: url('../../assets/images/ic_textsms_24px.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  
    .popup-form { 
      padding: 30px 30px 45px 30px;
  
      p {
        margin: 20px 0;
        font-weight: 300;
        font-size: 16px;
      }
  
      .form-txt {
        font-size: 16px;
        font-weight: 700;
        margin: 0 0 7px 0;
      }
  
      input {
        border: 1px solid #E9E9E9;
        border-radius: 3px;
        width: 48px;
        height: auto;
        padding: 14px 15px 10px 15px;
        font-size: 16px;
        color: #4A494A;
        line-height: 22px;
        display: inline-block;
        margin: 4px;
        text-align: center;
      }
  
      .btn {
        display: block;
        margin: 0 auto;
      }
  
      .send-btn {
        color: #fff;
        padding: 0;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        margin: 7px auto;
        background-color: #e62b4a;
        border: none;
        line-height: 0;
        font-size: 0;
        padding: 18px;
  
        img {
          width: 100%;
        }
      }
  
      .resend-txt {
        color: #4A494A;
        font-size: 14px;
        line-height: 22px;
        margin: 20px 0 0 0;
      }
  
      .resend-btn {
        text-decoration: underline;
        margin-top: 7px;
        border: none;
        background-color: transparent;
      }
    }
  }


  .master_popup { 
    .book-close-btn {
        position: absolute;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        background-color: #e62b4a;
        top: -18px;
        right: -18px;
        padding: 11px 10px 10px 10px;
        font-size: 0;
        line-height: 0;
        z-index: 1;
    }
    .text-danger {
        margin: 5px 0 0 0;
        font-size: 14px;
    }
    input {
        padding: 8px 15px;
        height: auto;
    }

    .btn-disable
    {
        padding: 12px 45px;
        font-size: 16px;
        line-height: 28px;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
        background-color: #999;
        cursor: not-allowed;
        width: 100%;
        max-width: 340px;
        &:hover, &:focus {
            color: #fff;
            cursor: not-allowed;
        }
    }

    .pop_btnsec {
        margin: 30px 0 0 0;
        .btn-custom {
            max-width: 300px;
            margin: auto;
            display: inherit;
        }
    }
     
}
.steps {
    width: 100%; 
	ul {
		display: flex;
		position: relative;
		li {
			width: 30%;
			margin-right: 10px;
			a {
                display: inline-block;
                width: 100%;
                height: 7px;
                background: #e6e6e6;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;
                
                .number{
                    margin: 11px 0 0 0;
                    display: block;
                    font-size: 12px;
                }
			}
			&.first, &.active {
				a {
					background: #16CF50;
					transition: all 0.5s ease;
				}
			}
		}
		// &:before {
		// 	content: "Billing Details";
		// 	font-size: 22px;
		// 	font-family: "Poppins-SemiBold";
		// 	color: #333;
		// 	top: -38px;
		// 	position: absolute;
		// }
		// &.step-2 {
		// 	&:before {
		// 		content: "Additional Information";
		// 	}
		// }
		// &.step-3 {
		// 	&:before {
		// 		content: "Your Order";
		// 	}
		// }
		// &.step-4 {
		// 	&:before {
		// 		content: "Billing Method";
		// 	}
		// }
    }
    .step_tite{
        font-size: 22px;
        font-weight: 600;
        line-height: 36px;
        margin: 0 0 20px 0;
        color: #4A494A;       
    }
}
.book-appointment-popup {

    .modal-header {
         padding: 30px 60px 10px 60px;
         border-bottom: 1px solid #e6e6e6;
         background: #f9f9f9;
    }

    .modal-body{
        padding: 30px 60px 50px 60px;
        .step-1 {

        }
        .book-popup-form {
            label{
                font-size: 15px;
                color: #666666;
                margin: 0 0 8px 0;
            }
            .form-control {
                border: 1px solid #e6e6e6;
            }
        }
        .pop_btnsec {
            margin: 30px 0 0 0;
        }
        
        .step-2 {
            .step_info {
                margin: 0;
                span{
                    display: block;
                    font-weight: 700;
                }
            }
        }
        
    }

    .ex_newpop {
        .pop_btnsec {
            .btn-custom {
                padding: 08px 30px;
            }
        } 
    }
}
.phonevfy {
    .vinput {
        text-align: center;
        margin: 40px 0;
        input {
            border: 1px solid #E9E9E9;
            border-radius: 3px;
            width: 48px;
            height: auto;
            padding: 14px 15px 10px 15px;
            font-size: 16px;
            color: #4A494A;
            line-height: 22px;
            display: inline-block;
            margin: 4px;
            text-align: center;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
        }
    }
    .resend-txt {
        color: #4A494A;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        margin: 20px 0 0 0;
    }

    .resend-btn {
        text-decoration: underline;
        margin-top: 7px;
        border: none;
        background-color: transparent;
    }
}

//////////////////////////// Public-popup


.public_popup { 

    .modal-dialog {
        max-width: 650px !important;
    }

    .modal-header {
        padding: 30px 60px 10px 60px;
        border-bottom: 1px solid #e6e6e6;
        background: #f9f9f9;

        .media {
            width: 100%;
            margin: -60px 0 0 0;
        }

        .img-box {
            height: 110px;
            width: 110px;
            overflow: hidden;
            border: 4px solid #FFFFFF;
            border-radius: 50%;
            display: inline-block;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
        }

        .p-name {
            font: Bold 20px/30px Roboto;
            padding: 0 0 7px 0;
        }
    }

    .modal-body {
        padding: 30px 60px 50px 60px;
        .publp_title {
            text-align: center;
            h6{
                font-size: 22px;
                font-weight: 600;
                line-height: 36px;
                margin: 25px 0;
                color: #4A494A;
            }
        }

        .publp_form {
            .bookingTime
            {
                position: relative;
                z-index: 0;                      
                input 
                {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin:auto;
                    width: 100%;
                    height: 100%; 
                    opacity: 0;
                }    
                span 
                {
                    padding:15px;
                    border-radius: 5px;
                    border: 1px solid #E9E9E9;
                    display: block;     
                    margin: 0 7px;
                    text-align: center;
                    min-width: 120px;
                }   

                input:checked + span
                {
                    background-color: #F9F9F9;
                    border-color: #4A494A;
                    font-weight: 600;
                }
            }
            .note-txt
            {
                margin: 30px 0 0 0;
                font-size: 14px;
                text-align: center;
                a 
                {
                    font-weight: 500;
                    text-decoration: underline;
                    color: #212529;
                }
            }
            input.dr-selected
            {
                opacity: 0;
                width: calc(100% - 30px);
                height:  calc(100% - 30px);
                position: absolute;
                top: 0;
                bottom: auto;
                right: 0;
                left: 0;
                margin: 0 auto auto auto;
                cursor: pointer;
            }

            .media
            {
                border: 2px solid #E9E9E9;
                border-radius: 5px;
                padding: 15px;
                margin:0 0 30px 0;
                text-align: left;  
                cursor: pointer;
            }
            input.dr-selected:checked + .media
            {
                background-color: #F9F9F9;
                border-color: #f295a4;
            }
            .media-img 
            {
                height: 48px;
                width: 48px;
                overflow: hidden;
                border-radius: 50%;
            }
            .dr-name
            {
                font-size: 16px;
                font-weight: 700;
            }
            .dr-desg
            {
                font-size: 14px;
                font-weight: 400;
            }
            .radio-box
            {
                border: 1px solid #E9E9E9;
                border-radius: 5px;
                padding: 15px;
                margin:0 0 30px 0;
                text-align: left;
                width: 100%;
                font-weight: 700;
                text-align:center;
                input 
                {
                    display: none;
                }
            }
            select 
            {
                padding: 10px;
                height: auto;
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                background-image: url(../images/dropdown-icon.svg);
                background-repeat: no-repeat;
                background-position: calc(100% - 20px) center;
                background-size: 12px;
                cursor: pointer;
                width: 100%;
                border-radius: 5px;
                border: 1px solid #E9E9E9;
            }
            textarea
            {
                width: 100%;
                border: 1px solid #E9E9E9;
                border-radius: 5px;
                padding: 15px;
                resize: none;
            }
            input 
            {
                width: 100%;
                border: 1px solid #E9E9E9;
                border-radius: 5px;
                padding: 15px;
            } 
        }

    }

    .step-3 {
        .pop_btnsec {
            justify-content: center;
            .btn-custom {
                max-width: 170px;
                margin: 0 10px;
                display: block;
                text-align: center;
            }
        }
    }
}

.login_popup {
    .modal-dialog {
        max-width: 350px;
    }
    .modal-header {
        padding: 15px 30px;
        background: #fafafb;
    }
    .modal-body {
        padding: 30px 30px;
        form {
            .btn-custom {
                padding: 7px 30px;
            }
            button {
                .spinner-border {
                    margin: 6px 10px 0 0;
                }
            }
        }
        .fgpass {
            text-align: right; 
            display: block;
            text-decoration: underline;
            color: #95989a;
            font-size: 14px;
            line-height: 22px; 
        }
    }
}

.helPopup {
    .modal-dialog {
        max-width: 350px;
    }
    .modal-content {
        background: #E62B4A;
        padding: 30px 50px 50px 50px;
        border-radius: 10px 10px 10px 100px ;
        -webkit-border-radius: 10px 10px 10px 100px ;
        -moz-border-radius: 10px 10px 10px 100px ;
        -ms-border-radius: 10px 10px 10px 100px ;
        -o-border-radius: 10px 10px 10px 100px ;
    }
    .hptitle {
        font-size: 40px;
        font-weight: 600;
        text-align: left;
        color: #fff;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        margin: 0 0 20px 0;
    }
    .hp-text {
        font-size: 24px;
        line-height: 34px;
        text-align: left;
        color: #fff;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        margin: 0 0 20px 0;
    }
    .btn-white {
        border-radius: 6px;
        background: #fff;
        box-shadow: 0px 10px 15px rgba(37, 90, 150, 0.1);
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
        color:#4A494A;
        padding: 10px 50px;
        margin: 20px 0 0 0;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 767px)
{
    .public_popup 
    {
        .modal-header 
        {
            padding: 40px 20px 0px;
            .img-box
            {
                height: 80px;
                width: 80px;
            }
            .media-body
            {
                padding: 30px 0 0;
            }
        }
        .modal-body
        {
            padding: 30px 30px 50px;
            
        }
        
    }
}