.claim-reward
{
    .page-title
    {
        font-weight: 700;
        margin: 0 0 15px 0;
    }
    p 
    {
        font-size: 18px;
        position: relative;
        margin: 0 0 30px 0;
        padding: 0 0 15px 0;
        &::after
        {
            max-width: 440px;
            width: 100%;
            position: absolute;
            content: '';
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            height: 1px;
            background-color: #E9E9E9; 
        }
    }
    .cl_gift {
        font-weight: 600;
        color: #e62b4a;
    }
    label 
    {
        font-size: 16px;
        font-weight: 700;
        display: block;
        margin: 15px 0;
    }
    .ratting-desc
    {
        span
        {
            color: #95989A;
            font-size: 14px;
            margin: 15px 0;
            display: block;
        }
    }
    .form-section
    {
        padding: 0 15px 15px 15px;

        .form-group
        {
            margin: 0 0 45px 0;         
            input[type=date]
            {
                background-color: #00C9A71A;
                border: 1px solid #00C9A726;
                border-radius: 5px;
                width: 100%;
                max-width: 260px;
                margin: auto;
                height: auto;
                text-align: center;
                padding: 14px 15px;
            }
            textarea
            {
                box-shadow: 0px 3px 10px #0000000D;
                border: 1px solid #E9E9E9;
                border-radius: 5px;
                padding: 30px;
                margin: auto;
                max-width: 560px;
                width: 100%;
            }
        }
        .upload-sec
        {            
            .file-sec
            {
                margin: 5px 0 10px 0;
                a
                {
                    padding: 0 2px;
                    img 
                    {
                        height: 18px;
                        width: auto;
                    }
                }
                span 
                {
                    font-size: 16px;
                    color: $font-color;
                }
            }           
            input[type=file] 
            {
                display: none;
            }
            label
            {
                cursor: pointer;
                width: 100%;
                max-width: 290px;
                padding: 7px;
                margin: auto;
                border-radius: 0.25rem;
                text-align: center;
                background: #4A494A 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 10px #0000000D;
                border: 1px solid #4A494A;
                border-radius: 5px;

                color: #fff;
                i 
                {
                    margin-right: 8px;
                }
            }
        }    
        .rate-range
        {   
            a 
            {
                color: #E9E9E9;
                display: inline-block;
                background-color: #fff;
                height: 44px;
                width: 44px;
                border: 1px solid #E9E9E9;
                line-height: 44px; 
                font-size: 18px;
                font-weight: 700;
                text-align: center;
                border-radius: 50%;
            }
            .active
            {
                color: #fff;  
                background-color: #16CF50;
                border: 1px solid #E9E9E9;
            }
        }
    }

    .df-select
    {
        padding: 13px 15px;
        height: auto;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url(../images/dropdown-black.svg);
        background-repeat: no-repeat;
        background-position: 95% center;
        background-size: 12px; 
        max-width: 350px;
        margin: auto;
        position: relative;
        text-align: center;
    }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .claim-reward .form-section .rate-range a
    {
        height: 24px;
        width: 24px;
        line-height: 26px;
        font-size: 14px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 359px) {
    .claim-reward .form-section .rate-range a
    {
        height: 22px;
        width: 22px;
        line-height: 24px;
        font-size: 14px;
    }

}