.global_loder {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(0,0,0,.6);
    }
    .global_loder svg {
    width: 100px;
    height: 100px;
    margin: 20px;
    display: inline-block;
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    }