.myclinic-list {
    li.mycli {
        box-shadow: 0px 1em 2em -1.5em rgba(0, 0, 0, 0.1);
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        padding: 30px;
        position: relative;
        margin: 0 0 30px 0;
        background: #ffffff;
        transition: all ease-in-out 300ms;
        -webkit-transition: all ease-in-out 300ms;
        -moz-transition: all ease-in-out 300ms;
        -ms-transition: all ease-in-out 300ms;
        -o-transition: all ease-in-out 300ms;

        .media {
            .media-img {
                max-height: 60px;
                max-width: 60px;
                border-radius: 50%;
                overflow: hidden;
                align-self: center;
            }
            .media-body {
                .cl-name {
                    font-size: 18px;
                    font-weight: 700;
                    color: #4A494A;
                    line-height: 26px;
                }
                .cl-loc {
                    font-size: 16px;
                    font-weight: normal;
                    color: #95989a;
                }
            }
        }

    }
}