.login-page {
    height: calc(100vh - 54px);
    padding: 0;
    background-color: transparent;
}
.login-sec {
    box-shadow: 0px 3px 10px #0000000d;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    .login-title {
        background-color: #fafafb;
        padding: 20px 30px;
        font-size: 22px;
        font-weight: 700;
        color: $font-color;
        border-bottom: 1px solid #e9e9e9;
        p {
            font-weight: 400;
            line-height: 22px;
            margin: 10px 0 0 0;
        }
    }
    .login-form {
        background-color: #fff;
        padding: 30px;
        label {
            font-size: 16px;
            font-weight: 700;
            color: $font-color;
        }
        input {
            border: 1px solid #e9e9e9;
            border-radius: 3px;
            height: auto;
            padding: 10px 15px;
            font-size: 16px;
            color: $font-color;
            line-height: 22px;
        }
        .btn {
            color: #fff;
            text-align: left;
            padding: 8px 30px 8px 30px;
            line-height: 26px;
            font-size: 14px;
            font-weight: normal; 
            .aft_load {
                &.right {
                    float: right;
                }
            }
        }
        .fprelink {
            a {  
                display: block;
                text-decoration: underline;
                color: #95989a;
                font-size: 14px;
                line-height: 22px;
                margin: 15px 0 0 0;
            }
        }
        
         
    }
}
