/* --- Common CSS Start --- */
$font-color: #4A494A;
$secondary-color : #e62b4a;
$aqua-color: #fff;
$primary-color: #2E88CD;
$title-color:#777F88;
@mixin overlay() {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    margin: auto;
}
body
{
    font-family: 'Overpass', sans-serif;
    font-weight: 400;
    color: $font-color;
    background-color: #FAFAFB;
}
.container
{
    max-width: 1170px;
}
p
{
    font-size: 16px;
    line-height: 26px; 
    // margin: 0 0 30px 0;
}
a 
{
    text-decoration: none;
    outline: 0;
    &:hover , &:focus , &:active
    {
        text-decoration: none;
        box-shadow: none!important;
    }
}
textarea
{
    resize: none;
}
.like-h1
{
    font-size: 40px;
    line-height: 50px;
    color: $font-color;
    font-weight: 300;
    strong
    {
        font-weight: 700!important;
    }
}
ul
{
    list-style: none;
    padding: 0;
}
section
{
    background-color: #fff;
    padding: 60px 0;
}
.btn-custom
{
    padding: 12px 45px;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    border-radius: 3px;
    margin: 0;
    color: #fff;
    background-color:  $secondary-color;
    border: none;
    &:hover , &:focus , &:active
    {
        color: #fff;
    }
}
.page-title
{
    font-size: 18px;
    color: $font-color;
    margin: 0 0 30px 0;
}
input
{
    height: auto;
}
.error
{
    font-size: 14px;
    margin: 8px 0 0 0;
}
.pantient-view 
{
    background-color: #FAFAFB;
}
.status
{
    border-radius: 5px;
    font-size: 14px;
    color: $font-color;
    padding: 7px 30px;
    border-style: solid;
    border-width: 1px;
    text-align: center;
    font-weight: 600;
}
.scheduled
{
    background-color: #02D3EF1A;   
    border-color: #02D3EF26;
    color: #02D3EF;
}
.requested
{
    background-color: #FFC1071A ;   
    border-color: #FFC10726;
    color: #FFC107;
}
.cancelled
{
    background-color: #DE44361A;   
    border-color: #DE443626;
    color: #DE4436;
}
.completed
{
    background-color: #00C9A71A;   
    border-color: #00C9A726;
    color: #00C9A7;
}
.cs_point{
    cursor: pointer;
}
.varification-status
{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 0 15px;
    border-radius: 5px;
    margin:0 0 0 10px;
    position: relative;
    top:-2px;
    font-weight: 600;
}
.varification-status.verified
{
    background-color: #00C9A71A;
    border: 1px solid #00C9A726;
    color: #00C9A7;
}
.varification-status.unverified
{
    background-color: #FFC1071A;
    border: 1px solid #FFC10726;
    color: #FFC107;
}
.varification-status.verify
{
    background-color: #DE44361A;
    border: 1px solid #DE443626;
    color: #DE4436;
}
.aft_load {
    margin: 0 0 0 10px;
    &.right {
        float: right;
    }
}
.noti_covid {
    padding: 20px 30px;
    margin: 0 0 30px 0;
    p {
        margin: 0;
    }
}

.yes-no
    { 
        input 
        {
            display: none;
        }
        label
        {
            height: 50px;
            width: 50px;
            background-color: #fff;    
            border-radius: 50%;
            margin: 0;
            line-height: 42px;
            border-width: 2px;
            border-style: solid;
            cursor: pointer;
            text-align: center;
        }
        .l-no
        {
            border-color: #FC1D1D;
        }
        .l-yes
        {
            border-color: #16CF50;
        }
        input:checked ~ label.l-no
        {
            background-color:rgba($color: #FC1D1D, $alpha: 0.3);
        }     
        input:checked ~ label.l-yes
        {
            background-color: rgba($color: #16CF50, $alpha: 0.3) ;
        } 
    }

    .cmsoon {
        margin-top: 50px;
        text-align: center;
        h1 {
            font-size: 24px;
            line-height: 34px;
        }
    }

.new-ex {
    text-align: center;
    .exptitl {
        font-size: 20px;
    }
}

.sbyid {
    text-align: center;
    .exptitl {
        font-size: 20px;
    }
    .form-group {
        max-width: 300px;
        margin: auto;
    }
    .media {
        border: 1px solid #ced4da;
        background: #fff;
        padding: 10px 20px;
        border-radius: 6px;
        text-align: left;
        align-items: center;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        max-width: 400px;
        margin: 0 auto 20px auto;
        cursor: pointer;
        img
        {
            width: 80px;
        }
        h5 {
            margin: 0;
        }
        p {
            margin: 0;
            color: #B1B1B1;
        }
    }
}


/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
 .StripeElement {
    box-sizing: border-box;
  
    height: 40px;
  
    padding: 10px 12px;
  
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
  
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
.stripe_outerform {
    background: #fff;
    padding: 40px;
    box-shadow: 0px 1em 2em -1.5em rgba(0, 0, 0, 0.1);
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;

    .btn {
    float: right;
    margin: 30px 0 0 0;
    }
}

/* --- Common CSS End --- */
/* --- Pages CSS Start --- */
@import '_elements.scss';
@import '_header.scss';
@import '_footer.scss';
@import '_login.scss';
@import '_referal.scss';
@import '_account.scss';
@import '_appointments.scss';
@import '_appointments-detail.scss';
@import '_claim-status.scss';
@import '_claim-reward.scss';
@import '_navigation-menu.scss';
@import '_popup.scss';
//@import '_verification-popup.scss';

/* --- Pages CSS End --- */

/* --- Responsive Start --- */
@keyframes spinHorizontal {
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(360deg); }
}
@keyframes zoomTip {
    0% { transform: scale(1); -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); }
    50% { transform: scale(1.2); -webkit-transform: scale(1.2); -moz-transform: scale(1.2); -ms-transform: scale(1.2); -o-transform: scale(1.2); }
    100% { transform: scale(1); -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) { 
}
@media only screen and (min-width: 992px) and (max-width: 1199px) { 

}
@media only screen and (min-width: 768px) and (max-width: 991px) { 

}
@media only screen and (min-width: 320px) and (max-width: 767px) { 

}
@media only screen and (min-width: 360px) and (max-width: 440px)
{

}
@media only screen and (min-width: 320px) and (max-width: 359px) {

}
/* --- Responsive End --- */
