/* --- Footer section start --- */
.master-footer
{
    padding: 60px 0 0 0;
    border-top: 1px solid rgba(74,73,74,0.1);
    hr 
    {
        margin: 60px 0 0 0;
    }
    h6 
    {
        font-size: 18px;
        line-height: 32px;
        font-weight: 700;
        color: $font-color;
    }
    p 
    {
        width: 75%;
    }
    .link-list
    {
        list-style: none;
        padding: 0;
        margin: 0;
        li 
        {
            a 
            {
                font-size: 16px;
                line-height: 32px;
                font-weight: 400;
                color: $font-color;
                &:hover
                {
                    color: $secondary-color;
                }
            }
        }
    }
    .social-links
    {
        padding: 0;
        margin: 20px 0;
        list-style: none;
        li 
        {
            display: inline-block;
            a 
            {
                background-color: #fff;
                display: block;
                margin: 0 2px;
                padding: 0 6px;
                position: relative;
                i 
                {
                    color: $font-color;
                    font-size: 18px;
                    line-height: 34px;
                    transition: .5s;
                }
                &:hover
                {                   
                    i 
                    {
                        color:$secondary-color;
                    }
                }
            }
        }
    }
    .social-title
    {
        font-weight: 400;
        margin: 5px 0;
        font-size: 18px;
        line-height: 32px;
        color: $font-color;
    }
    .bottom-links
    {
        padding: 0;
        text-align: center;
        margin: 0;
        li 
        {
            display: inline-block;
            font-size: 16px;
            line-height: normal;
            font-weight: 400;
            color: $font-color;
            padding: 0 7px;
            a 
            {
                display: block;
                font-size: 16px;
                line-height: normal;
                font-weight: 400;
                color: $font-color;
                &:hover
                {
                    color: $secondary-color;
                }
            }
            &:nth-child(2)
            {
                border: 1px solid $font-color;
                border-top: 0;
                border-bottom: 0;
            }
        }
    }
}
/* --- Footer section end --- */
