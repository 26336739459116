/* --- Refer page start --- */
.refer-page
{
    .ref_box {
        border-radius: 5px;
        background: #fff;
        border: 1px solid #e9e9e9;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
        padding: 50px;
        .title-sec {
            .lbimg {
                margin: 0 30px 0 0;
                img {
                    width: 100px;
                }
            }
            .peql {
                color: #16CF50;
                font-size: 22px;
                font-weight: 600;
                margin: 0 0 20px 0;
            }
            p 
            {
                margin: 15px 0;
            }   
            h2 
            {
                font-size: 34px;
                line-height: 44px;
                font-weight: 300;
                margin: 16px 0 30px 0;
                strong{
                    font-weight: 600;
                }
                .ref-val
                {
                    color: #16CF50;
                }
            }
            h6
            {
                color: #2E88CD;
                font-size: 20px;
                margin: 0 0 15px 0;
            }  
        }
        .refBtns {
            margin: 0 0 30px 0;
        }
        .btn{
            text-transform: uppercase;
            padding: 12px 30px;
        }

        .field-sec{
            .ref_hid {
                background-color: #F9F9F9;
                border-top: 1px solid #E9E9E9; 
                padding: 30px 50px;
                margin: 0 -50px 0 -50px;
                .input-section 
                {
                    position: relative;
                    z-index: 0;     
                    input 
                    {
                        width: 100%;
                        padding: 10px 15px;
                        border: 1px solid #E9E9E9;
                        color: #444444;
                        margin: 0 0 0 0;
                    }      
                    .react-multi-email {
                        margin: 0 0 20px 0;
                    } 
                }
                .refBtns {
                    margin: 0;
                }
                
            }
            label
            {
                color: #444444;
                font-size: 14px;
                font-weight: 700;
                margin: 0 0 15px 0;
                display: block;
            }
            .link-section 
            { 
                input
                {
                    padding:0px;
                    border:none;
                    box-shadow: none;
                    background: none;
                    display: block;
                    width: 100%;
                }
                .btn
                {
                    padding-left: 30px;
                    padding-right: 30px;    
                    img 
                    {
                        position: relative;
                        top:-1px;
                    }
                }
            }
            
        }
        
    }
    
    .copy_lnksn
    {
        background-color: #F9F9F9;
        border-top: 1px solid #E9E9E9;
        border-radius: 0 0 5px 5px;
        -webkit-border-radius: 0 0 5px 5px;
        -moz-border-radius: 0 0 5px 5px;
        -ms-border-radius: 0 0 5px 5px;
        -o-border-radius: 0 0 5px 5px;
        padding: 30px 50px;
        margin: 0 -50px -50px -50px;
        .copied
        {
            margin: 0;
            position: relative;
            top: 10px;
            font-size: 12px;
        }
        
    }
     
    .referal-graphic
    {
        max-width: 500px;
        width: 100%;
        height: auto;
    }
    
}
/* --- Refer page end --- */
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .refer-page .title-sec
    {
        margin: 30px 0 0 0;
        text-align: center;
    }
    .refer-page .title-sec h2
    {
        font-size: 28px;
        line-height: 48px;
    }
    .refer-page .ref_box
    {
        padding: 30px 30px 0;
        .d-flex
        {
            display: block!important;
        }
        .title-sec .lbimg
        {
            margin: 0;
        }
        .title-sec h2
        {
            margin: 30px 0;
            font-size: 30px;
            line-height: 40px;
        }
        .refBtns
        {
            text-align: center;
            .btn 
            {
                margin: 0 auto 15px auto!important;
                width: 100%;
                max-width: 200px;
            }
        }
        .copy_lnksn
        {
            margin: 0 -30px;
            padding: 30px;
        }
        .field-sec .link-section input
        {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .refer-page .referal-graphic
    {
        margin: 30px 0 0 0;
    }
}
.btn-black {
    background: #4A494A;
}