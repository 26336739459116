/* --- Appointments page start --- */
@keyframes mymove {
    0% {
        transform: scale(0);
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
    }
    100% {
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
    }
}
.appointment-list {
    margin: 0;
    li.apl {
        box-shadow: 0px 1em 2em -1.5em rgba(0, 0, 0, 0.1);
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        padding: 30px;
        position: relative;
        margin: 0 0 30px 0;
        background: #ffffff;
        transition: all ease-in-out 300ms;
        -webkit-transition: all ease-in-out 300ms;
        -moz-transition: all ease-in-out 300ms;
        -ms-transition: all ease-in-out 300ms;
        -o-transition: all ease-in-out 300ms;
        .dr-review-link
        {
            position: absolute;
            left: 105px;
            bottom: 20px;
        }
        .media {
            color: $font-color;
            .media-img {
                max-height: 60px;
                max-width: 60px;
                border-radius: 50%;
                overflow: hidden;
                align-self: center;
            }
            .media-body {
                .dr-name {
                    font-size: 18px;
                    font-weight: 700;
                    color: $font-color;
                    line-height: 26px;
                }
                .clinic-name {
                    font-size: 16px;
                    font-weight: normal;
                    color: #95989a;
                }
            }
        }
        .claim_rw {
            margin: 0 0 0 10px;
            background-color: #16cf50;
            border-color: #16cf50;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            .btn_ico {
                margin: -4px 4px 0 0;
            }
        }
        .rw-link {
            margin: 0 20px 0 0;
            .btn {
                border-radius: 5px;
                font-size: 14px;
                color: #4A494A;
                padding: 7px 20px; 
                text-align: center;
                font-weight: 600; 
                border: 1px solid #ccc;
            }
        }
    }
    .claim-status {
        height: auto;
        animation: mymove 0.2s ease-in-out;
        -webkit-animation: mymove 0.2s ease-in-out;
        transform-origin: right center;
        transform: scale(0.9);
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
        position: absolute;
        right: 40px;
        background: #fff;
        height: 100%;
        top: 0;
        padding: 25px 0;
        .step-wizard {
            .step {
                display: inline-block;
                width: 160px;
                position: relative;
                z-index: 0;
                text-align: center;
                span.icon {
                    line-height: 42px;
                    text-align: center;
                }
            }
        }
    }
    .btn-circle_ico {
        background-color: $secondary-color;
        border-radius: 50%;
        height: 48px;
        width: 48px;
        text-align: center;
        margin: auto;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        margin: 0;
    }
    .aps_date {
        align-self: center;
        margin: 0 30px 0 0;
        font-weight: 700;
        font-size: 14px;
        span {
            display: block;
            font-weight: 400;
        }
    }
    .apt_prescrt {
        align-self: center;
        margin: 0 30px 0 0;
        font-weight: 700;
        font-size: 14px;
        border: 1px solid #e9e9e9;
        box-shadow: 0px 1em 2em -1.5em rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 8px 15px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        span {
            display: block;
            font-weight: 400;
        }

        .media-img {
            img {
                width: 34px;
            }
        }


        &.disabled {
            color: #d3d3d3;
            .media{
                opacity: 0.4;
                filter: grayscale(1);
                -webkit-filter: grayscale(1);
            }             
            &.verified {
                .media{
                    opacity: 1;
                    filter: grayscale(0);
                    -webkit-filter: grayscale(0);
                }     
                span {
                    color: #16cf50;
                }
            }
            &.completed {
                background: #fff;
                .media{
                    opacity: 1;
                    filter: grayscale(0);
                    -webkit-filter: grayscale(0);
                }     
                span {
                    color: #02D3EF;
                }
            }     
        }
    }

    .apt_checkin {
        align-self: center;
        margin: 0 30px 0 0;
        font-weight: 700;
        font-size: 14px;
        border: 1px solid #e9e9e9;
        box-shadow: 0px 1em 2em -1.5em rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 8px 15px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        span {
            display: block;
            font-weight: 400;
        }

        .media-img {
            border-radius: 0 !important;
            -webkit-border-radius: 0 !important;
            -moz-border-radius: 0 !important;
            -ms-border-radius: 0 !important;
            -o-border-radius: 0 !important;

            img {
                width: 24px;
            }
        }
        &.disabled * {
            color: #d3d3d3;
            cursor: not-allowed;
            img {
                filter: grayscale(1);
                opacity: 0.3;
                -webkit-filter: grayscale(1);
            }
        }
    }

    .paystatus {
        align-self: center;
        margin: 0 30px 0 0;
        font-weight: 400;
        font-size: 14px;
        color: #fff;
        border: 1px solid #e9e9e9;
        padding: 8px 30px;
        box-shadow: 0px 1em 2em -1.5em rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        background-color:#6772e5 ;
        span{
            display: block;
            font-weight: 700;
        }
    }
}

.model_covid {
    text-align: center;
    .modal-header {
        text-align: center;
        background: #fafafb;
        padding: 15px 30px;
        .modal-title {
            font-size: 30px;
            font-weight: 600;
            align-self: center;
        }
        .close {
            margin: 0 -20px 0 0;
        }
    }
    .modal-dialog {
        max-width: 360px;
    }
    .covdque {
        margin: 0 0 30px 0;
    }
    .yes-no {
        margin: 0 10px 30px;
        span {
            margin: 0 0 0 10px;
        }
    }
    .andpbtn {
    }
    .modal-body {
        padding: 30px;
    }
    .deslist {
        text-align: left;
        margin: 30px 0 30px 0;
    }
}

.chk_in {
    text-align: center;
    .modal-header {
        text-align: center;
        background: #fafafb;
        padding: 15px 30px;
        .modal-title {
            font-size: 30px;
            font-weight: 600;
            align-self: center;
        }
        .close {
            margin: 0 -20px 0 0;
        }
    }
    .modal-dialog {
        max-width: 300px;
    }

    .modal-body {
        padding: 0;
    }
    .checkinlist {
        text-align: left;
        margin: 0;
        label {
            span {
                display: block;
                font-size: 14px;
                color: #999999;
            }
        }
    }
}
.write_review
{
    .modal-body
    {
        padding: 15px 30px;
        text-align: center;
    }
    label 
    {
        font-size: 16px;
        font-weight: 700;
        display: block;
        margin: 15px 0;
    }
    .rate-range
    {   
        a 
        {
            color: #E9E9E9;
            display: inline-block;
            background-color: #fff;
            height: 32px;
            width: 32px;
            border: 1px solid #E9E9E9;
            line-height: 32px; 
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            border-radius: 50%;
        }
        .active
        {
            color: #fff;  
            background-color: #16CF50;
            border: 1px solid #E9E9E9;
        }
    }
    .ratting-desc span 
    {
        color: #95989A;
        font-size: 14px;
        margin: 15px 0;
        display: block;
    }
    .form-group textarea 
    {
        box-shadow: 0px 3px 10px #0000000D;
        border: 1px solid #E9E9E9;
        border-radius: 5px;
        padding: 30px;
        margin: auto;
        max-width: 560px;
        width: 100%;
    }
    .pop_btnsec
    {        
        text-align: center;
        .btn
        {
            max-width: 300px;
            margin: 15px auto 7px auto;
        }
    }

}

 


@media only screen and (min-width: 320px) and (max-width: 767px) {
    .appointment-list {
        margin: 0;
        li {
            padding: 15px;
            margin: 0 0 30px 0;
            .media {
                .media-img {
                    display: inline-block;
                    max-height: 44px;
                    max-width: 44px;
                    margin-right: 10px !important;
                }
                .media-body {
                    display: inline-block;
                    width: calc(100% - 60px);
                    text-align: left;
                    .dr-name {
                        font-size: 16px;
                        line-height: 24px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .clinic-name {
                        font-size: 14px;
                        font-weight: normal;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .status {
                    padding: 7px 0;
                    font-size: 13px;
                    float: none !important;
                    width: 100px;
                    margin: 7px auto 0 auto;
                }
            }
        }
        li.apl {
            display: block !important;
            text-align: center;
            .dr-review-link
            {
                position: relative;
                left: 70px;
                bottom: 0;
                text-align: left;
            }
            .aps_date {
                display: block;
                width: 100%;
                margin: 15px 0;
            }
            .status {
                max-width: 230px;
                margin: 0 auto;
            }
            .claim_rw {
                width: 100%;
                margin: 15px auto 0;
                max-width: 230px;
            }
        }
    }
    .write_review
    {
        .rate-range
        {   
            a 
            {               
                height: 22px;
                width: 22px;               
                line-height: 22px; 
                font-size: 12px;
            }           
        }
    }
}
