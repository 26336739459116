.appointment-detail-sec
{
    padding: 30px 0;
    position: relative;
    overflow-x: hidden;
    .page-title
    {
        font-weight: 700;
        margin: 15px 0;
    }
    .desc-content
    {
        color: #95989A;
        font-size: 16px;
        line-height: 26px;
    }
    .ui-nav, #nav-1 , #nav-2
    {
        display: none;
    }
}
.detail-sec
{
    border-right: 1px solid #E9E9E9;
    margin: 15px 0 0 0;
    padding: 30px 0 0 0; 
    .info-sec
    {
        border-bottom: 1px solid #E9E9E9;
        padding: 0 30px 30px 0;
        margin: 0 0 30px 0;
        &:last-child
        {
            border-bottom: none;
            margin: 0;
            padding-bottom: 0;
        }
        .switch
        {
            position: relative;
            top:-20px
        }
        .media-img a
        {
            font-size: 14px;
            color: #2E88CD;
            font-weight: 700;
        }
        .view-btn
        {
            font-size: 16px;
            padding: 12px 0;
            width: 100%;
            margin: 15px 0 0 0;
        }
    }
    .profile
    {
        .profile-img
        {
            max-width: 128px;
            max-height: 128px;
            border: 1px solid #707070;
            overflow: hidden;
            border-radius: 50%;
        }
        .profile-info
        {
            h5
            {
                color: $font-color;
                font-size: 24px;
                font-weight: 700;
                margin: 0 0 15px 0;
            }

            .heading
            {
                margin: 15px 0 0 0;
                font-size: 16px;
                color: #95989A;
                img 
                {
                    margin: -4px 8px 0 0;
                }        
            }        
        }
    }
    .map-sec
    {
        box-shadow: 0px 3px 10px #0000000D;
        border: 1px solid #E9E9E9;
        border-radius: 5px;
        overflow: hidden;
        margin: 15px 0 0 0;
    }
}
.provider-chat
{
    height:calc(100vh - 60px);
    border-radius: 5px;
    margin: 15px 0 0 0;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 30px;
    z-index: 0;
    padding: 100px 0 85px 0;
    overflow: hidden;
    border: 1px solid #E9E9E9;
    background: #00C9A70D 0% 0% no-repeat padding-box;

    .title-box
    {
        padding:28px 15px 24px 15px;
        text-align: center;
        background-color:#FAFAFB;
        color: $font-color;
        font-weight: 700;
        font-size: 18px;
        width: 100%;
        position: absolute;
        top:0;
        left: 0;
        z-index: 2;
        border-bottom: 1px solid #E9E9E9;
    }
    .input-box
    {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top:1px solid #E9E9E9;
        textarea 
        {
            width: 100%;
            padding: 20px 150px 20px 30px;
            background-color: #fff;
            border: none;
            display: block;
            height: 84px;            
        }
        .enter-btn
        {          
            color: #fff;
            display: block;
            background: $secondary-color 0% 0% no-repeat padding-box;
            border-radius: 3px;
            position: absolute;
            right: 30px;
            bottom: 0;
            width: 98px;
            height: 40px;
            top:0;
            margin: auto;
            padding: 12px 0;
            line-height:16px;
        }
    }
    .chat-box
    {
        height: 100%;
        overflow-y: scroll;
        padding: 0 30px;
        position: relative;
        .chat{  
            width: 100%;
            margin: 0 0 15px 0;          
            &.dr
            { 
                .chat-bubble
                {
                    float: left;
                    border: 1px solid #E9E9E9;
                    background-color: #fff;
                    border-radius: 15px 15px 15px 0;
                    -webkit-border-radius: 15px 15px 15px 0;
                    -moz-border-radius: 15px 15px 15px 0;
                    -ms-border-radius: 15px 15px 15px 0;
                    -o-border-radius: 15px 15px 15px 0;
                }
                .msg_date {text-align: left;}
            }
            &.user
            {
                .chat-bubble {
                    float: right;
                    background-color: #16cf50;
                    border-radius: 15px 15px 0 15px;
                    color: #ffffff;
                    border: 1px solid #16cf50;
                    -webkit-border-radius: 15px 15px 0 15px;
                    -moz-border-radius: 15px 15px 0 15px;
                    -ms-border-radius: 15px 15px 0 15px;
                    -o-border-radius: 15px 15px 0 15px;
                    }
                .msg_date {text-align: right;}
            }
            .chat-bubble {
                max-width:  80%;
                padding:10px 15px;
                display: block;
                clear: both;
                margin: 0 0 10px 0;
            }
            .msg_date {
                display: block;
                clear: both;
                font-size: 12px;
                color: #999;

            } 
            &::after {
                display: table;
                content: "";
                clear: both;
            }
        }     
        .alertparent {
            width: 100%;
            display: block;
        }  
        .alert{
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);    
            &.chat_covid_alert{   
                color: #4A494A;
                border: 1px solid #dcdcdc;
                img {
                    margin: 0 10px 0 0;
                }
            }
            &.chat_checkin {
                color: #4A494A;
                border: 1px solid #dcdcdc;
                img {
                    margin: 0 10px 0 0;
                }
            }
        }
    }
}
.tabbar
{
    display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) 
{
    .detail-sec .info-sec
    {
        padding: 0 15px 30px 0;
    }
    .provider-chat .chat-box
    {
        padding: 0 20px;
    }
    .provider-chat .input-box .enter-btn
    {
        right: 20px;
    }
    .provider-chat .input-box input
    {
        padding: 30px 135px 25px 20px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 767px) 
{
    .detail-sec
    {
        border: 1px solid #E9E9E9;
        border-radius: 5px;
    }
    .detail-sec .info-sec , .detail-sec .info-sec:last-child
    {
        padding: 0 15px 30px 15px;
    }
    
    .provider-chat .title-box
    {
        font-size: 15px;
    }
    .provider-chat .chat-box
    {
        padding: 0 15px;
    }
    .provider-chat .input-box .enter-btn
    {
        right: 15px;
    }
    .provider-chat .input-box input
    {
        padding: 25px 125px 20px 15px;
    }
    
    .apt_prescrt, .apt_checkin  {
        margin: 20px 0 !important;
    }

    
    

}

