/* --- Account page start --- */
.account-settings
{
    .account-fields
    {
        box-shadow: 0px 1em 2em -1.5em rgba(0, 0, 0, 0.1);
        border: 1px solid #E9E9E9;
        overflow: hidden; 
        margin: 0 0 -1px 0;
        &:first-child
        {
            border-radius: 5px 5px 0px 0px;
        }
        &:last-child
        {
            border-radius: 0px 0px 5px 5px;
        }
        .ac-field-value
        {
            font-size: 16px;
            color:$font-color;
            font-weight: normal;
            background-color: #fff;
            padding: 30px;
            .ac-title
            {
                font-weight: 700;
                margin-bottom: 7px;
            }
            .ac-value 
            {
                span 
                {
                    display: block;
                }
            }
            .edit-btn
            {
                font-size: 14px;
                list-style: 26px;
                font-weight: 400;
                color: $font-color;
            }
        }
        .ac-form
        {
            background-color: #F9F9F9;
            /*border-top: 1px solid #E9E9E9;*/
            padding: 30px;
            label
            {
                color: #95989A;
                font-weight: 700;
                font-size: 16px;
                margin: 0 0 7px 0;
            }
            input 
            {
                background-color: #fff;
                padding:12px 15px 8px 15px;
                border: 1px solid #E9E9E9;
                border-radius: 3px;
                color: $font-color;
                line-height: 26px;
                height: auto;
            }
            input[type=date]
            {
                background-image: url(../images/dropdown-black.svg);
                background-repeat: no-repeat;
                background-position: 95% center;
                background-size: 12px;
                cursor: pointer;
            }
            select 
            {
                padding: 13px 15px 9px 15px;
                height: auto;
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                background-image: url(../images/dropdown-black.svg);
                background-repeat: no-repeat;
                background-position: 95% center;
                background-size: 12px;
                cursor: pointer;
            }
            .btn 
            {
                margin: 7px 0 0 0;
                padding: 12px 15px;
            }
            .btn-save
            {
                background-color: #E62B4A;

            }
            .btn-cancel
            {
                background-color: #4A494A;
            }            
        }

    }
}
/* --- Account page end --- */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .account-settings .account-fields .ac-field-value
    {
        .varification-status
        {
            margin: 15px 0 0;
            display: block;
            max-width: 110px;
            text-align: center;
        }
    }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .account-settings .account-fields .ac-field-value
    {
        width: 100%!important;
        padding: 30px 15px;
    }
    .account-settings .account-fields .ac-field-value .ac-value.w-50
    {
        width: 100%!important;
        max-width: 220px;
    }
    .account-settings .account-fields .ac-form input
    {
        text-overflow: ellipsis;
    }
    .account-settings .account-fields .ac-field-value
    {
        .varification-status
        {
            margin: 15px 0 0;
            display: block;
            max-width: 110px;
            text-align: center;
        }
    }
}
@media only screen and (min-width: 320px) and (max-width: 359px) {
    .account-settings .account-fields .ac-form{
        padding: 25px;
    }
}