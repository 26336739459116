.claim-status
{
    height: calc(100vh - 188px);
    .step-wizard
    {
        list-style: none;
        .step
        {
            display: inline-block;
            width: 24%;
            position: relative;
            z-index: 0;
            &::after
            {
                position: absolute;
                top:22px;
                right: 50%;
                height: 4px;
                width:calc(100% - 24px) ;
                z-index: -1;
                content: '';
                background-color: #16CF50;
            }
            &:first-child
            {
                &::after
                {
                    display: none;
                }
            }
            span 
            {
                display: block;
            } 
            span.icon
            {               
                border-radius: 50%;
                height: 48px;
                width: 48px;
                line-height: 42px;
                text-align: center;                
                margin: auto;                
            }
            span.name
            {
                color: $font-color;
                margin: 7px 0;
                font-weight: 700;
                font-size: 16px;
            }
        }
        .active
        {
            span.icon
            {
                background-color: #16CF50;   
                border: 1px solid #E9E9E9;
            }
        }

        .disable
        {
            span.icon
            {
                background-color: #fff;
                border:1px solid #95989A;
                color: #95989A;
                font-size: 18px;
                font-weight: 700;
            }
            span.name
            {
                color: #95989A;
            }
            &::after
            {
                background-color: #E9E9E9;
            }
        }
    }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .claim-status .step-wizard{
        transform: scale(0.9);
    }
}

